import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { Module } from 'vuex';
import ConfigurationState from '@/types/ConfigurationState';
import RootState from '@/types/RootState';

const namespaced = true;

const configuration: Module<ConfigurationState, RootState> = {
    namespaced,
    state,
    getters,
    mutations,
    actions,
};

export default configuration;
