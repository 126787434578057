import ConfigurationState from '@/types/ConfigurationState';
import { GetterTree } from 'vuex';
import RootState from '@/types/RootState';

const getters: GetterTree<ConfigurationState, RootState> = {
    label(state: ConfigurationState) {
        return (key: string): string => {
            const defaultLabel = `${key.toUpperCase()}_LABEL`;

            if (!state.labels) {
                return defaultLabel;
            }

            const label = (state.labels as any)[key];

            return label || defaultLabel;
        };
    },

    item(state: ConfigurationState) {
        return (key: string): string | null => {
            if (!state.spreadsheet) {
                return null;
            }

            return (state.spreadsheet as any)[key];
        };
    },
};

export default getters;
