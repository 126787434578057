
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import moment, { Moment } from 'moment';
    import { Getter } from 'vuex-class';
    import Icon from '@/components/Icon.vue';
    import Remark from '@/components/Remark.vue';
    import Label from '@/enums/Label';
    import SportField from '@/types/SportField';

@Component({
    components: {
        'afk-icon': Icon,
        'afk-remark': Remark,
    },
})
    export default class ListItem extends Vue {
        @Prop()
    readonly sportsField!: SportField;

        @Prop({ required: false, default: false, type: Boolean })
    readonly closeable!: boolean;

        @Getter('label', { namespace: 'configuration' })
    readonly label!: (key: Label) => string;

        get name(): string {
            const {
            name,
            field_number: number,
            field_status: status,
        } = this.sportsField;

            return `${name} (${number}): ${status}`;
        }

        get close(): string {
            return this.label(Label.CLOSE);
        }

        get lastInspection(): string {
            return this.label(Label.LAST_INSPECTION);
        }

        get updatedAt(): Moment {
            return moment(this.sportsField.updated_at);
        }
    }
