
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Getter, Mutation } from 'vuex-class';
    import Menu from '@/enums/Menu';

    const namespace: string = 'app';

@Component
    export default class PanelMenuItem extends Vue {
        @Prop({ required: true, type: String })
    readonly label!: string;

        @Prop({ required: true, type: String })
    readonly item!: Menu;

        @Getter('isActive', { namespace })
    readonly isActive!: (item: Menu) => boolean;

        @Mutation('setActivePanel', { namespace })
    readonly setActivePanel!: (item: string) => void;
    }
