import RootState from '@/types/RootState';
import { GetterTree } from 'vuex';
import SportPark from '@/types/SportPark';
import SportParksState from '@/types/SportParksState';

const getters: GetterTree<SportParksState, RootState> = {
    sportsParks(state: SportParksState): SportPark[] {
        return state.sportParks;
    },
};

export default getters;
