import { ActionContext, ActionTree } from 'vuex';
import ConfigurationRepository from '@/repositories/ConfigurationRepository';
import RootState from '@/types/RootState';
import ConfigurationState from '@/types/ConfigurationState';

const actions: ActionTree<ConfigurationState, RootState> = {
    async fetchConfiguration(context: ActionContext<ConfigurationState, RootState>): Promise<void> {
        const response = await (new ConfigurationRepository(context.state.file!)).get();

        context.commit('setConfiguration', response);
    },
};

export default actions;
