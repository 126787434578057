import Vue from 'vue';
import App from './App.vue';
import store from './store';
import moment from 'moment';

Vue.config.productionTip = false;

let selector: string = '#app';

moment.locale('nl');

(async() => {
    const meta = document.querySelector(
        'meta[name="application-configuration"]'
    );

    if (meta) {
        await store.commit(
            'configuration/setConfigurationFile',
            meta.getAttribute('content')
        );
        await store.dispatch('configuration/fetchConfiguration');
        await store.dispatch('sportparks/fetchSportParks');

        if (store.state.configuration && store.state.configuration.selector) {
            selector = store.state.configuration.selector;
        }

        new Vue({
            store,
            render: h => h(App),
        }).$mount(selector);
    }
})();
