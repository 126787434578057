import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { Module } from 'vuex';
import RootState from '@/types/RootState';
import SportParksState from '@/types/SportParksState';

const namespaced = true;

const sportparks: Module<SportParksState, RootState> = {
    namespaced,
    state,
    actions,
    getters,
    mutations,
};

export default sportparks;
