import axios from 'axios';
import ConfigurationResponse from '@/types/ConfigurationResponse';

export default class ConfigurationRepository {
    url: string;

    constructor(url: string) {
        this.url = url;
    }

    async get(): Promise<ConfigurationResponse> {
        const response = await axios.get(this.url);

        return response.data;
    }
}
