import AppState from '@/types/AppState';
import { MutationTree } from 'vuex';
import Menu from '@/enums/Menu';

const mutations: MutationTree<AppState> = {
    setActivePanel(state: AppState, item: Menu): void {
        state.activePanel = item;
    },
};

export default mutations;
