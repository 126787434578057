
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { Mutation, State } from 'vuex-class';
    import Leaflet from 'leaflet';
    import wellknown from 'wellknown';
    import colors from '@/utils/colors';
    import SportField from '@/types/SportField';
    import SportPark from '@/types/SportPark';

    const namespace: string = 'sportparks';

@Component
    export default class Map extends Vue {
        map!: Leaflet.Map;
        fieldsLayer!: Leaflet.LayerGroup;

        @Prop({ required: true, type: Array })
    readonly sportFields!: SportField[];

        @Mutation('setSelectedSportField', { namespace })
    readonly setSelectedSportField!: (sportField: SportField) => void;

        @State('selectedSportsPark', { namespace })
    readonly selectedSportsPark!: SportPark;

        @State('selectedSportField', { namespace })
    readonly selectedSportField!: SportField;

        get url(): string {
            return `https://www.swis.nl/mappi?utm_source=${window.location.hostname}&utm_medium=referral&utm_campaign=mappi-logo`;
        }

        mounted(): void {
            this.map = new Leaflet.Map(this.$el as HTMLElement);

            this.addTileLayer();
            this.addFieldsLayer();
            this.addSportFieldsToMap();
        }

        get stylesForField(): Record<string, Leaflet.PathOptions> {
            return Object.values(this.selectedSportsPark.sportsfields).reduce(
            (acc: Record<string, Leaflet.PathOptions>, sportField) => {
                let options = {
                    color: '#FFFFFF',
                    fillColor: colors[sportField.field_status],
                    fillOpacity: 0.8,
                    weight: 3,
                };

                if (
                    this.selectedSportField &&
                    sportField.id === this.selectedSportField.id
                ) {
                    options = {
                        ...options,
                        color: '#000000',
                    };
                }

                acc[sportField.id.toString()] = options;

                return acc;
            },
            {}
        );
        }

        addTileLayer(): void {
            const tiles = Leaflet.tileLayer(
            'https://{s}.data.amsterdam.nl/topo_google/{z}/{x}/{y}.png',
                {
                    minZoom: 11,
                    maxZoom: 21,
                    subdomains: ['t1', 't2', 't3', 't4'],
                    detectRetina: true,
                }
        );

            this.map.addLayer(tiles);
        }

        addFieldsLayer(): void {
            this.fieldsLayer = new Leaflet.LayerGroup();
            this.map.addLayer(this.fieldsLayer);
        }

        @Watch('selectedSportsPark')
    @Watch('selectedSportField')
    addSportFieldsToMap(): void {
        this.fieldsLayer.clearLayers();

        if (this.selectedSportsPark.sportsfields.length === 0) {
            return;
        }

        const boundsOfSportsPark: Leaflet.LatLngBounds[] = [];

        this.selectedSportsPark.sportsfields.forEach(sportField => {
            const geoJSON = wellknown.parse(sportField.geo) as any;

            const layer = Leaflet.geoJSON(geoJSON, {
                style: this.stylesForField[sportField.id.toString()],
            });

            layer.bindTooltip(`${sportField.name} (${sportField.field_number}) ${sportField.field_status}`);

            layer.on('click', () => {
                this.setSelectedSportField(sportField);
            });

            this.fieldsLayer.addLayer(layer);

            boundsOfSportsPark.push(layer.getBounds());
        });

        // @ts-ignore
        this.map.fitBounds(boundsOfSportsPark);
    }
    }
