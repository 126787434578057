
    import { Component, Vue } from 'vue-property-decorator';
    import { Getter, Mutation, State } from 'vuex-class';
    import Label from '@/enums/Label';
    import SportPark from '@/types/SportPark';

    const namespace: string = 'sportparks';

@Component
    export default class SportsParkSelect extends Vue {
        @State('selectedSportsPark', { namespace })
    readonly selectedSportsPark!: SportPark;

        @Getter('sportsParks', { namespace })
    readonly sportsParks!: SportPark[];

        @Getter('label', { namespace: 'configuration' })
    readonly label!: (key: Label) => string;

        @Mutation('setSelectedSportsPark', { namespace })
    readonly setSelectedSportsPark!: (sportsPark: SportPark) => void;

        get chooseASportsPark(): string {
            return this.label(Label.CHOOSE_A_SPORTS_PARK);
        }

        get sportsPark(): SportPark {
            return this.selectedSportsPark;
        }

        set sportsPark(value: SportPark) {
            this.setSelectedSportsPark(value);
        }

        option(sportPark: SportPark): string {
            return sportPark.name.replace('Sportpark ', '');
        }
    }
