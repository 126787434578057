enum Label {
    CHOOSE_A_SPORTS_PARK = 'choose_a_sports_park',
    CLOSE = 'close',
    LAST_INSPECTION = 'last_inspection',
    LIST = 'list',
    MAP = 'map',
    REMARK = 'remark',
}; // eslint-disable-line

export default Label;
