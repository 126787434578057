import AppState from '@/types/AppState';
import RootState from '@/types/RootState';
import { GetterTree } from 'vuex';
import Menu from '@/enums/Menu';

const getters: GetterTree<AppState, RootState> = {
    isActive(state: AppState) {
        return (item: Menu): boolean => {
            return state.activePanel === item;
        };
    },
};

export default getters;
