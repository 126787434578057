
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Getter } from 'vuex-class';
    import Label from '@/enums/Label';

@Component
    export default class Remark extends Vue {
        @Prop({ required: true, type: String })
    readonly remark!: string;

        @Getter('label', { namespace: 'configuration' })
    readonly label!: (key: Label) => string;

        get tag(): string {
            return this.label(Label.REMARK);
        }
    }
