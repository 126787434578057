import ConfigurationResponse from '@/types/ConfigurationResponse';
import ConfigurationState from '@/types/ConfigurationState';
import { MutationTree } from 'vuex';

const mutations: MutationTree<ConfigurationState> = {
    setConfigurationFile(state: ConfigurationState, file: string): void {
        state.file = file;
    },

    setConfiguration(state: ConfigurationState, response: ConfigurationResponse): void {
        state.spreadsheet = response.spreadsheet;
        state.labels = response.labels;
        state.selector = response.selector;
    },
};

export default mutations;
