import { MutationTree } from 'vuex';
import SportParksState from '@/types/SportParksState';
import SportPark from '@/types/SportPark';
import SportField from '@/types/SportField';

const mutations: MutationTree<SportParksState> = {
    setSportParks(state: SportParksState, sportParks: SportPark[]): void {
        state.sportParks = sportParks;
    },

    setSelectedSportsPark(state: SportParksState, sportsPark: SportPark): void {
        state.selectedSportsPark = sportsPark;
    },

    setSelectedSportField(state: SportParksState, field: SportField | null): void {
        state.selectedSportField = field;
    },
};

export default mutations;
