
    import { Component, Vue } from 'vue-property-decorator';
    import { Getter, Mutation, State } from 'vuex-class';
    import InspectionsPanel from '@/components/Panel.vue';
    import ListItem from '@/components/ListItem.vue';
    import Map from '@/components/Map.vue';
    import Menu from '@/enums/Menu';
    import Heading from '@/components/Heading.vue';
    import SportPark from '@/types/SportPark';
    import SportField from '@/types/SportField';

    const namespace: string = 'sportparks';

@Component({
    components: {
        'afk-heading': Heading,
        'afk-map': Map,
        'afk-panel': InspectionsPanel,
        'afk-list-item': ListItem,
    },
})
    export default class Inspections extends Vue {
        @State('selectedSportsPark', { namespace })
    readonly selectedSportsPark!: SportPark;

        @State('selectedSportField', { namespace })
    readonly selectedSportField!: SportField;

        @Getter('isActive', { namespace: 'app' })
    readonly isActive!: (item: Menu) => boolean;

        @Getter('remarkSportsPark', { namespace })
    readonly remarkSportsPark!: string | null;

        @Mutation('setSelectedSportField', { namespace })
    readonly setSelectedSportField!: (sportField: SportField | null) => void;

        get isList(): boolean {
            return this.isActive(Menu.LIST);
        }

        get isMap(): boolean {
            return this.isActive(Menu.MAP);
        }
    }
