import axios from 'axios';
import SitebossResponse from '@/types/SitebossResponse';

export default class SitebossRepository {
    async get(): Promise<SitebossResponse> {
        const response = await axios.get(process.env.VUE_APP_VELDSTATUS_API);

        return response.data;
    }
}
