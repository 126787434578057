
    import PanelMenuItem from '@/components/PanelMenuItem.vue';
    import { Component, Vue } from 'vue-property-decorator';
    import { Getter } from 'vuex-class';
    import Label from '@/enums/Label';
    import Menu from '@/enums/Menu';

@Component({
    components: {
        'afk-panel-menu-item': PanelMenuItem,
    },
})
    export default class Panel extends Vue {
        menuList: Menu = Menu.LIST;
        menuMap: Menu = Menu.MAP;

        @Getter('label', { namespace: 'configuration' })
    readonly label!: (key: Label) => string;

        get list(): string {
            return this.label(Label.LIST);
        }

        get map(): string {
            return this.label(Label.MAP);
        }
    }
