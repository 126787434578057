import AppState from '@/types/AppState';
import getters from '@/store/modules/app/getters';
import mutations from '@/store/modules/app/mutations';
import RootState from '@/types/RootState';
import state from '@/store/modules/app/state';
import { Module } from 'vuex';

const namespaced = true;

const app: Module<AppState, RootState> = {
    namespaced,
    state,
    getters,
    mutations,
};

export default app;
