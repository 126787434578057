import app from './app';
import configuration from './configuration';
import sportparks from './sportparks';

const modules = {
    app,
    configuration,
    sportparks,
};

export default modules;
