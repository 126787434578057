import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import createPersistedState from 'vuex-persistedstate';

const plugins = [
    createPersistedState({
        key: 'afgelastingenkaart',
    }),
];

Vue.use(Vuex);

export default new Vuex.Store({
    modules,
    plugins,
});
