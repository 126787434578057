
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import Examination from '@/enums/Examination';
    import colors from '@/utils/colors';
    import ApprovedIcon from '@/assets/images/goedgekeurd.svg';
    import ReinspectionIcon from '@/assets/images/herkeuring.svg';
    import RejectedIcon from '@/assets/images/afgekeurd.svg';

@Component
    export default class Icon extends Vue {
        @Prop({ required: true })
    readonly examination!: Examination;

        get icon(): string {
            switch (this.examination) {
                case Examination.APPROVED:
                    return ApprovedIcon;
                case Examination.REINSPECTION:
                    return ReinspectionIcon;
                case Examination.REJECTED:
                default:
                    return RejectedIcon;
            }
        }

        get backgroundColor(): object {
            return {
                'background-color': colors[this.examination],
            };
        }
    }
