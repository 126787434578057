
    import { Component, Vue } from 'vue-property-decorator';
    import Inspections from '@/components/Inspections.vue';
    import Search from '@/components/SportsParkSelect.vue';
    import { Action } from 'vuex-class';

    const namespace: string = 'sportparks';

@Component({
    components: {
        'afk-inspections': Inspections,
        'afk-sports-park-select': Search,
    },
})
    export default class App extends Vue {
        interval: number = 0;

        @Action('fetchSportParks', { namespace })
    readonly fetchSportParks!: () => void;

        mounted(): void {
            this.interval = setInterval(() => {
                this.fetchSportParks();
            }, 60000);
        }

        beforeDestroy(): void {
            clearInterval(this.interval);
        }
    }
