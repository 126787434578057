import { ActionContext, ActionTree } from 'vuex';
import RootState from '@/types/RootState';
import SitebossRepository from '@/repositories/SitebossRepository';
import SportParksState from '@/types/SportParksState';

const actions: ActionTree<SportParksState, RootState> = {
    async fetchSportParks(
        context: ActionContext<SportParksState, RootState>
    ): Promise<void> {
        const sportsParks = await new SitebossRepository().get();

        context.commit('setSportParks', sportsParks);

        if (!context.state.selectedSportsPark) {
            context.commit(
                'setSelectedSportsPark',
                context.getters.sportsParks[0]
            );
        } else {
            context.state.sportParks.forEach(park => {
                if (
                    context.state.selectedSportsPark &&
                    park.name === context.state.selectedSportsPark.name
                ) {
                    context.commit('setSelectedSportsPark', park);
                }
            });
        }
    },
};

export default actions;
