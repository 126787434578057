import ConfigurationState from '@/types/ConfigurationState';

const state: ConfigurationState = {
    selector: null,
    file: null,
    spreadsheet: null,
    labels: null,
};

export default state;
