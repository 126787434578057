
    import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
    export default class Heading extends Vue {
        @Prop({ required: true, type: String })
    readonly title!: string;

        @Prop({ required: false, type: String })
    readonly subtitle!: string;
    }
